<template>
  <div class="page-wrapper">
    <b-container fluid>
      <bo-page-title></bo-page-title>
      <b-card no-body>
        <b-card-header>
          <b-row>
            <b-col lg="2">
              <h5 class="card-title">Audit Trail</h5>
            </b-col>
            <b-col lg="3">
              <b-form-group>
                <b-form-datepicker id="example-datepicker" v-model="value" class="mb-2"></b-form-datepicker>
              </b-form-group>
            </b-col>
            <b-col lg="3">
              <b-form-group>
                <b-form-datepicker id="example-datepicker2" v-model="value2" class="mb-2"></b-form-datepicker>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-input-group>
                <b-form-input placeholder="Type keyword then enter..."></b-form-input>
                <b-input-group-append>
                  <b-button variant="success"><i class="fas fa-search"></i></b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
        </b-card-header>
        <b-card-body class="p-0">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>#</th>
                <th>Full Name</th>
                <th>Activity</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in users" :key="user.id">
                <td>{{user.no}}</td>
                <td>{{user.fullname}}</td>
                <td>{{user.activity}}</td>
                <td>{{user.date}}</td>
              </tr>
            </tbody>
          </table>
        </b-card-body>
        <b-card-footer>
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" prev-text="Prev" next-text="Next">
          </b-pagination>
        </b-card-footer>
      </b-card>
    </b-container>
  </div>
</template>
<script>
    import GlobalVue from '@/libs/Global.vue'
    export default {
    name: 'Article',
    extends: GlobalVue,
    components: {},
    data() {
      return {
        rows: 30,
        perPage: 1,
        currentPage: 5,
        value: '',
        value2: '',
        users: [{
            no: 1,
            fullname: 'Super Admin',
            activity: 'BO-OPEN-MENU-AUDIT-TRAIL',
            date: '02 August 2021, 11:01'
          },
          {
            no: 2,
            fullname: 'Super Admin',
            activity: 'BO-OPEN-MENU-ADD-USER-LEVEL',
            date: '02 August 2021, 11:01'
          },
          {
            no: 3,
            fullname: 'Super Admin',
            activity: 'BO-OPEN-MENU-USER-LEVEL',
            date: '02 August 2021, 11:01'
          }
        ]
      }
    }
  }
</script>